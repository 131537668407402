html,
body {
    overflow-x: hidden;
}
body {
    position: relative;
    background-color: "#0B0B0C";
}

::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-track {
    background-color: "#f1f1f123";
}

::-webkit-scrollbar-thumb {
    background-color: "#f1f1f190";
    width: 1px;
}

/* @font-face {
    font-family: "Inter";
    src: url("./assets/fonts/Inter.ttf") format("truetype");
} */

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Hanken Grotesk", sans-serif,
        serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
